<template>
    <div>
        <multi-selectbox
            v-model="selected"
            :options="computedOptions"
            :class="validateError != '' ? 'box-border-color':''"
            @input="handleInput"
            :disabled="disabled"
            :validate-error="validateError"
            :multiple="false"
        >

        </multi-selectbox>
    </div>
</template>

<script>
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox.vue";

export default {
    components: {MultiSelectbox},
    props: {
        value: {
            default: null
        },
        validateError: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        showOther:{
            type: Boolean,
            default: true
        }
    },
    watch: {
        value: function (newValue) {
            this.selected = newValue
        }
    },
    created() {
        this.selected = this.value
    },
    data() {
        return {
            selected: null,
            options: [
                {value: null, text: this.$t('select')},
                {value: 'Female', text: this.$t('female')},
                {value: 'Male', text: this.$t('male')},
                {value: 'Other', text: this.$t('other')}
            ]
        }
    },
    methods: {
        handleInput(event) {
            this.$emit('input', event)
        }
    },
    computed: {
        computedOptions(){
            return this.options.filter(item=>{
                return this.showOther || item.value!='Other';
            })
        }
    }
}
</script>
